/* Base styling for the calendar-page */
.calendar-page {
  height: 100vh;
  background: linear-gradient(0deg, #14d4df 0%, #ec18fd 40%, white 80%, white 100%);
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  overflow: hidden;
  transition: background 1s ease;
}

/* Flipping state changes background color */
.calendar-page.flipping {
  /* Adjust background color if needed */
}

/* Container for the logo */
.logo-container {
  text-align: center;
  padding: px;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* Links that will disappear during flip */
.link {
  cursor: pointer;
  color: white;
  background-color: blue;
  padding: 10px 20px;
  border-radius: 10px;
  
}

/* .link:hover {
  opacity: 0.8;
} */

/* Hide links when flipping */
.hide-links {
  opacity: 0;
  pointer-events: none; /* Disable interaction when hidden */
}

.show-links {
  opacity: 1;
}

/* "X" Button for flipping back */
.close-button {
  position: absolute;
  bottom: 30px;
  font-size: 48px; /* Increase size for visibility */
  cursor: pointer;
  /* color: white; */
  opacity: 0; /* Start hidden */
  transform: rotateX(180deg); /* Start flipped */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.sample-button {
  font-size: 12px; /* Increase size for visibility */
  cursor: pointer;
  color: black;
  opacity: 100; /* Start hidden */
  transform: rotateX(0deg); /* Start flipped */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

/* Show the "X" when flipped */
.calendar-page.flipping .close-button {
  opacity: 1;
  transform: rotateX(0); /* Appear when flipped */
}

/* Additional class to show "X" */
.show-x {
  opacity: 1;
  
  
}

.hide-x {
  opacity: 0;
  transform: rotateX(0);
}

.show-s {
  opacity: 1;
  
}

.hide-s {
    opacity: 0;
    transform: rotateX(0);
   /* opacity: 1;
  transform: rotateX(100); */
}

/* Bottom-right corner bending effect */
.corner-bend {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
  background-color: inherit;
  border-bottom-right-radius: 80px 80px;
  transform-origin: bottom right;
  transform: rotate(30deg);
  transition: transform 1s ease;
}

.calendar-page.flipping .corner-bend {
  transform: rotate(0deg);
}

/* .about {
  font-family: ;
} */